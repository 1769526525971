<template>
  <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
    <template v-slot:title>
      {{ title }}
    </template>
    <template v-slot:body>
      <b-row>
        <b-col sm="3">
          <b-form-input id="search" v-model="searchText"></b-form-input>
        </b-col>
        <b-col sm="1">
          <a class="btn btn-icon btn-light-success" @click="search()">
            <i class="flaticon2-search-1"></i>
          </a>
        </b-col>
      </b-row>
      <br />
      <b-table
        hover
        bordered
        no-border-collapse
        responsive="true"
        head-row-variant="dark"
        :items.sync="items"
        :fields="fields"
        :busy.sync="isBusy"
        head-variant="dark"
        @sort-changed="onSort"
      ></b-table>
      <b-pagination
        v-on:input="changePage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
      ></b-pagination>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_GENERAL_DEFINITION,
  SET_GENERAL_DEFINITION, SET_GENERAL_DEFINITION_FILTER, SET_GENERAL_DEFINITION_LOADING,
  SET_GENERAL_DEFINITION_PAGE, SET_GENERAL_DEFINITION_SORT
} from "@/core/services/store/faq/general_definition.module";

export default {
  name: "GeneralDefinition",
  data() {
    return {
      title: this.$t("MENU.GENERAL_DEFINITION"),
      currentPage: 1,
      totalRows: 1,
      perPage: 1,
      isBusy: true,
      searchText: "",
      items: [],
      fields: [
        {
          key: "name",
          sortable: true,
          label: this.$t("GENERAL_DEFINITION.TERM")
        },
        {
          key: "description",
          sortable: true,
          label: this.$t("GENERAL_DEFINITION.DEFINITION")
        }
      ]
    };
  },
  components: {
    KTCard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FAQ"), route: "general-definition" },
      { title: this.$t("MENU.GENERAL_DEFINITION") }
    ]);
    this.getItems();
  },
  computed: mapGetters([
    "getGeneralDefinitionData",
    "getGeneralDefinitionCurrentPage",
    "getGeneralDefinitionTotalPage",
    "getGeneralDefinitionTotalRow",
    "getGeneralDefinitionPerPage",
    "getGeneralDefinitionLoadingStatus"
  ]),
  methods: {
    changePage(ctx) {
      this.$store.dispatch(SET_GENERAL_DEFINITION_PAGE, ctx);
    },
    getItems() {
      this.$store.dispatch(GET_GENERAL_DEFINITION);
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_GENERAL_DEFINITION) {
          this.items = this.getGeneralDefinitionData;
          this.currentPage = this.getGeneralDefinitionCurrentPage;
          this.totalRows = this.getGeneralDefinitionTotalRow;
          this.perPage = this.getGeneralDefinitionPerPage;
        }

        if (mutation.type === SET_GENERAL_DEFINITION_LOADING) {
          this.isBusy = this.getGeneralDefinitionLoadingStatus;
        }
      });
    },
    onSort(ctx) {
      let a = {};
      a[ctx.sortBy] = ctx.sortDesc === true ? "desc" : "asc";
      this.$store.dispatch(SET_GENERAL_DEFINITION_SORT, a);
    },
    search() {
      this.$store.dispatch(SET_GENERAL_DEFINITION_FILTER, this.searchText);
    }
  }
};
</script>

<style scoped></style>
